/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
body {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  line-height: 26px;
  background-color: #F6F6F6;
  min-height: 100vh;
  padding-bottom: 70px;
}


.form-group {
  position: relative;
}

ul {
  list-style: none;
  margin-bottom: 0;
}
ol, ul{
  margin-left: 0px;
  padding-left: 0px;
}

button, a {
  -webkit-transition: .3s ease-in-out;
  -o-transition: .3s ease-in-out;
  transition: .3s ease-in-out;
}
a{
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  
}

button:focus, button:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
}

.blue_btn {
  background-color: #018d36;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 10px;
  padding: 12px 38px;
}

  .blue_btn:hover {
      background-color: #016634;
      color: #fff;
  }

.form-group{
  position: relative;
}
.form-control {
  min-height: 50px;
  border-radius: 5px;
  background-color: #fff;
  font-weight: 400;
  border: 1px solid #E2E2E2;
}


.form-control[type="file"] {
  line-height: 35px;
}

.form-control:active, .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  border-color: #5964FF;
  color: #000;
}
.form-control::placeholder{
  color: #727272;
}
.login_sec{
  padding-top: 30px;
}

._login_box .bg_box::before {
  content: none;
}

.login_logo {
  display: block;
  text-align: center;
}

.logfield_box {
  margin-top: 50px;
}
  .logfield_box p {
      font-weight: 500;
      color: #018d36;
      margin-bottom: 0;
  }
.logfield_box h1{
  font-weight: 700;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 30px;
}
.logfield_box .form-group{
  margin-bottom: 30px;
}
.logfield_box .form-group img{
  position: absolute;
  top: 14px;
  left: 20px;
  height: 20px;
  width: 20px;
}
.logfield_box .form-control{
  padding-left: 55px;
}
.logfield_box .blue_btn{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}
header {
  background: #018d36;
  padding: 15px 5px;
}
header div[class*="col"]{
  display: flex;
  align-items: center;
}
header .toggle_btn{
  background: none;
  color: #fff;
  border: 0;
  font-size: 20px;
}
header h1{
  font-size: 26px;
  padding-left: 45px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 0;
}
header a.logout{
  color: #fff;
  margin-left: auto;
  font-size: 20px;
}
header .logout span
{
    display: none;
}
.profile_box,
.service_list .box{
  background: #fff;
  margin: 15px 0;
  align-items: center;
  display: flex;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  border: 2px solid #D7D7D7;
}
.profile_box figure {
  border-right: 1px solid #D7D7D7;
  margin-bottom: 0;
  padding-right: 10px;

}
.profile_box figure img{
  height: 80px;
}
.profile_box .det{
  padding-left: 10px;
}
.profile_box .det h2{
  font-size: 18px;
  font-weight: 900;
}
.profile_box .det h3{   
  font-size: 14px;
  font-weight: 700;
  color: #727272;
}
.profile_box .det p{
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
}
.service_list .box{
  display: block;
  margin-top: 0;
  text-align: center;
  border-radius: 5px;
}
.service_list .box a{
  display: block;
}
.service_list .box img{
  height: 45px;
  width: 45px;
}
.service_list .box h4{
  font-size: 14px;
  color: #000;
  margin-top: 10px;
  margin-bottom: 0;
}
.botom_nav{
  background: #fff;
  position: fixed;
  left: 0;
  padding: 10px 0;
  bottom: 0;
  width: 100%;
}
.botom_nav ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  list-style: none;
  gap: 10px;
  margin-bottom: 0;
}
.botom_nav ul li a{
  display: block;
  text-align: center;
  color: #757575;
}
.botom_nav ul li a span{
  display: block;
  line-height: initial;
  font-size: 14px;
  margin-top: 6px;    
}
.botom_nav ul li a svg{
  height: 24px;
  width: 24px;
}
.botom_nav ul li a svg {
  fill:#757575
}
  .botom_nav ul li.active a svg {
      fill: #018d36;
  }
  .botom_nav ul li.active a {
      color: #018d36;
  }
.service_list .box{
  padding: 15px 10px;
}
aside{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  overflow-y: auto;
  z-index: 9999;
  background: #ffff;
  transition: .3s ease-in-out;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  
}


aside.slide{
  left: 0;
}
  aside .prof {
      background: #018d36;
      display: flex;
      padding: 15px 20px;
      justify-content: center;
      margin-bottom: 10px;
      align-items: center;
  }
      aside .prof span {
          height: 60px;
          width: 60px;
          color: #018d36;
          text-align: center;
          line-height: 60px;
          font-size: 21px;
          font-weight: 900;
          background: #fff;
          border-radius: 100%;
      }
aside ul {
  list-style: none;
  margin: 0;
}
aside ul li{
  margin-top: 2px;
  font-weight: 500;
}
aside ul li a{
  padding: 10px 20px;
  font-size: 14px;
  color: #000;
  display: flex;
  align-items: center;
}
aside ul li a span{
  margin-left: 20px;
}
aside ul li a svg{
  height: 25px;
  width: 25px;
  margin-right: 20px;
}
  aside ul li.active a svg path {
      fill: #018d36;
  }
aside ul li a svg path{
  fill: #727272;
}
aside ul li.active a{
  background: #72727224;
}
  aside ul li.active a,
  aside ul li a:hover {
      color: #018d36;
  }
aside h4{
  font-size: 14px;
  font-weight: 400px;
  color: #727272;
  border-top:1px solid #ddd;
  margin-top: 10px;
  padding: 20px;
  padding-bottom: 10px;
}
header a.back,
header.inner .search{
  color: #000;
  font-size: 20px;
}
header.inner{
  background: #fff;
  border-bottom: 2px solid #D7D7D7;
  padding: 16.5px 5px;
  position: relative;
}
header.inner h1{
  color: #000;
  font-weight: 600;
  font-size: 20px;
  padding-left: 30px;
}
  header.inner .search {
      color: #018d36;
      margin-left: auto;
  }
header.inner .search_box{ 
  margin-top: -8px;
  position: absolute;
  left: -500px;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  background: #fff;
  transition: .3s ease-in-out;
}
header.inner .search_box.show{
  left: 50%;
  transform: translateX(-50%);
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
header.inner .search_box input{
  border: 0;
  margin-left: 20px;
  padding: 8px 0;
}
header.inner .search_box input:active,
header.inner .search_box input:focus{
  outline: 0;
  box-shadow: none;
}
.invoice_list .box a{
  display: flex;
  background: #fff;
  border: 2px solid #D7D7D7;
  border-radius: 4px;
  margin-top: 5px;
  padding: 20px;
  gap: 30px;
  align-items: center;
}
.invoice_list .box figure{
  margin: 0;
}
.invoice_list .box p{
  margin-bottom: 0px;
  color: #000;
  font-size:14px;
}
.invoice_list .box p strong{
  font-weight: 700;
}
.invoice_list .box span {
  font-size: 14px;
  color: #018d36;
}
.invoice_list .box figure img{
  height: 60px;
  width: 60px;
}
.gr_number{
  display: flex;
  margin-top: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.gr_number > div h3{
  font-weight: 400;
  font-size: 16px;
}
.gr_number > div p{
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 700;
}
.gr_number > div:last-child{
  margin-left: auto;
}
.upload_inv > label{
  font-size: 12px;
  margin-bottom: 5px;
}
.custom-checkbox .custom-control-label::before{
  border-radius: 0;
  border: 2px solid #000;
}
.custom-control-input:checked~.custom-control-label::before{
  border-color: #5964FF;
  background: #5964FF;
}
.custom-control-input:focus~.custom-control-label::before{
  box-shadow: none;
}
.upload_inv .form-control, .upload_inv .up_invbox span{
  border: 1px solid #000;
  background: #f1f1f1;
  /*font-weight: 600;*/
  color: #000;
}
.upload_inv .up_invbox{
  position: relative;
}
.upload_inv .up_invbox span{
  display: block;
  text-align: center;
  line-height: 50px;
  border-radius: 5px;
  height: 50px;
  width: 100%;
}
.upload_inv .up_invbox span svg{
  position: absolute;
  left: 20px;
  top: 13px;
}
.upload_inv .up_invbox span svg path{
  fill: #5964FF;
}
.upload_inv .up_invbox input[type="file"]{
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
  opacity: 0;
}
.inv_smt {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #018d36;
  border: 0;
  color: #fff;
  font-weight: 600;
  height: 50px;
  font-size: 18px;
}
.upload_inv .date span{
  font-weight: 600;
  position: absolute;
  color: #727272;
  pointer-events: none;
  min-width: 120px;
  bottom: 12px;
  left: 13px;
  background: #f1f1f1;
}
.more_inv{
  display: none;
}
.more_inv .inv_head{
  background: #fff;
  border-bottom: 2px solid #D7D7D7;
  padding: 16.5px 10px;
  margin: 0 -10px;
  margin-bottom: 20px;
  position: relative;
}
.punch_time, .att_rqt{
  background: #fff;
  border: 2px solid #D7D7D7;
  border-radius: 4px;
  padding: 20px;
}
.punch_time h4{
  font-weight: 700;
  font-size: 26px;
  text-align: center;
}
.punch_time p{
  margin-bottom: 0;
  font-weight: 600;
  color: #757575;
  line-height: initial;
}
.location_box .form-control{
  border-radius: 0;
  border: 0;
  border-bottom: 2px solid #757575;
  background: transparent;
  font-weight: 600;
}
.location_box .form-group{
  display: flex;
  gap: 30px;
}
.location_box button{
  height: 60px;
  flex: 0 0 60px;
  border-radius: 4px;
  background: #fff;
  border: 2px solid #D7D7D7;
  font-size: 40px;
  color: #5964FF;
  width: 60px;
  margin-left: auto;
}
.punch_btn{
  line-height: 20px;
  border: 0;
  background: #5964FF;
  color: #fff;
  font-weight: 600;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  height: 100px;
  width: 100px;
  font-size: 18px ;
  border-radius: 100px;
}
.punch_btn.out{
  background: var(--danger);
}
.punch_btn:disabled{
  background: #D7D7D7;
}
.att_rqt .form-control{
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #727272;
  background: #fff;
}
.att_rqt .date span{
  background: #fff;
}
.att_rqt .form-group{
  margin-bottom: 30px;
}
.att_rqt .form-group label{
  margin-bottom: 2px;
}
.form-group label i {
  color: #018d36;
  font-size: 20px;
  margin-right: .5rem;
}
.att_rqt .date div[class*="col"] span{
  padding-left: 15px;
}

@media (max-width:575px){
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
      padding-left: 10px;
      padding-right:10px;
  }
}

@media (max-height:500px){
  .login_sec {
      padding-bottom: 60px;
  }
}
@media (min-width: 576px){
  .logfield_box .blue_btn{
      position: initial;
  }
}
@media(min-width:768px){
  header.inner .search_box{
      width: 55%;
  }
  .profile_box figure{
      padding-right: 30px;
  }
  .profile_box .det{
      padding-left: 30px;
  }
  .inv_smt{
      position: initial;
      margin-top: 20px;
  }
  .more_inv .inv_head{
      margin: 0 ;
      margin-bottom: 20px;
      background: none;
  }
}
@media (min-width: 992px){
._login_box {
  display: -webkit-box;
  display: -ms-flexbox;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  -webkit-box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.15);
}
.logfield_box {
  padding: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 55%;
  flex: 0 0 55%;
  max-width: 55%;
  margin-top: 0;
}
.logfield_box > img{
  width: 250px;
  margin: 25px auto;
}
}
.mandatory{
  color: #f32b2b;
}
.alertnote{
font-size: 18px;
font-weight: 700;
color: #018d36;
margin-bottom: 10px;
margin-top: 10px;
}
.alertnote a{
  display:inline-block!important ;
  margin-left: 20px;
  color: #018d36;
}